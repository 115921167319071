import { useState, useEffect, useContext, createContext } from "react";
import { getTokenPayload, getAccessToken, getRefreshToken } from "./tokens";
import { refreshTokens, verifyToken } from "./axios";

export const UserContext = createContext(null);

async function _updateUser(setUser) {
  await tryUpdateUserWithToken(setUser).catch((err) => {
    const refreshToken = getRefreshToken();
    refreshTokens(refreshToken).then(() => {
      tryUpdateUserWithToken(setUser).catch((err) =>
        console.log("Could not verify refreshed token:", err)
      );
    });
  });
}

function tryUpdateUserWithToken(setUser) {
  const promise = new Promise((resolve, reject) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      verifyToken(accessToken)
        .then(() => {
          const accessToken = getAccessToken();
          const user = getUserFromToken(accessToken);
          setUser(user);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      console.log("No access token available. User not logged in. ");
      setUser(null);
      resolve();
    }
  });
  return promise;
}

function getUserFromToken(token) {
  const payload = getTokenPayload(token);
  const user = {
    username: payload.username,
    isAdmin: payload.is_superuser,
  };
  return user;
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  async function updateUser() {
    await _updateUser(setUser);
  }

  useEffect(() => {
    _updateUser(setUser).then(() => setLoading(false));
    const minutes = 10;
    const interval = setInterval(updateUser, minutes * 60 * 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    !loading && (
      <UserContext.Provider value={{ user, updateUser }}>
        {children}
      </UserContext.Provider>
    )
  );
}

export function useUser() {
  return useContext(UserContext);
}
