import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function SpinnerPage() {
  const navbarHeight = 64; // TODO: store this value globally somehow, it's also in sidebarLayout
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `calc(100vh - ${navbarHeight}px)`,
      }}
    >
      <CircularProgress size={60} />
    </Box>
  );
}
