import axios from "axios";
import {
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  clearTokens,
  getTokenExpired,
} from "./tokens";

// const DEBUG_URL = "http://127.0.0.1:3001/";
// const LOCAL_URL = "http://127.0.0.1:8000/";
// const PROD_URL = "https://app.cityscale.turner-engineering.com/";
const BASE_URL = process.env.REACT_APP_BACKEND_URL; // Set in package.json and GH Action

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: getAccessToken() ? "JWT " + getAccessToken() : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    const response = error.response;
    const statusCode = response.status;
    const statusText = response.statusText;

    if (
      typeof response === "undefined" ||
      typeof response.data === "undefined"
    ) {
      // if other
      console.log(
        "A server/network error occurred. " +
          "Looks like CORS might be the problem. " +
          "Sorry about this - we will get it fixed shortly." +
          " Response or response data is undefined."
      );
      return Promise.reject(error);
    }

    if (
      statusCode === 401 && // if refresh didn't work
      originalRequest.url === BASE_URL + "token/refresh/"
    ) {
      window.location.href = "/login/";
      return Promise.reject(error);
    }

    if (statusCode === 500 && response.data.includes("InterfaceError")) {
      console.error(
        "Confirm that the CityScale server has access to the database"
      );
    }

    if (
      // if need a refresh
      response.data.code === "token_not_valid" &&
      statusCode === 401 &&
      statusText === "Unauthorized"
    ) {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const tokenExpired = getTokenExpired(refreshToken);
        if (!tokenExpired) {
          return axiosInstance
            .post("/api/token/refresh/", { refresh: refreshToken })
            .then(({ data }) => {
              setAccessToken(data.access);
              if (data.refresh) setRefreshToken(data.refresh); // refresh token may or may not be sent from the server

              axiosInstance.defaults.headers["Authorization"] =
                "JWT " + data.access;
              originalRequest.headers["Authorization"] = "JWT " + data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => console.log(err));
        } else {
          console.log("Refresh token is expired.");
          clearTokens();
          window.location.href = "/login/";
        }
      } else {
        console.log("Refresh token not available.");
        clearTokens();
        window.location.href = "/login/";
      }
    }
    return Promise.reject(error);
  }
);

export function login(username, password, updateUser) {
  const promise = new Promise((resolve, reject) => {
    return axiosInstance
      .post("/api/token/", {
        username,
        password,
      })
      .then(async ({ data }) => {
        setAccessToken(data.access);
        setRefreshToken(data.refresh);
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + getAccessToken();
        await updateUser();
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
}

export function logout(updateUser) {
  clearTokens();
  axiosInstance.defaults.headers["Authorization"] = null;
  updateUser();
}

const axiosBase = axios.create({
  baseURL: BASE_URL,
});

export function verifyToken(token) {
  return axiosBase.post("/api/token/verify/", { token });
}

export function refreshTokens(refreshToken) {
  return axiosBase
    .post("/api/token/refresh/", { refresh: refreshToken })
    .then(({ data }) => {
      setAccessToken(data.access);
      if (data.refresh) setRefreshToken(data.refresh); // refresh token may or may not be sent from the server
      axiosInstance.defaults.headers["Authorization"] = "JWT " + data.access;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        clearTokens();
        window.location.href = "/login/";
      } else {
        console.log(err);
      }
    });
}

export default axiosInstance;
