const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

// *** TOKEN GETTERS  ***
export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function tokensAvailable() {
  return getAccessToken() && getRefreshToken();
}

// *** TOKEN SETTERS ***
export function setRefreshToken(refreshToken) {
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function setAccessToken(accessToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export function clearTokens() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

// *** TOKEN CONTENT ***
export function getTokenPayload(token) {
  return JSON.parse(window.atob(token.split(".")[1]));
}

export function getTokenExpired(token) {
  const tokenPayload = getTokenPayload(token);
  // exp date in token is expressed in seconds, while now() returns milliseconds:
  const now = Date.now() / 1000;
  return tokenPayload.exp < now;
}
