import { Suspense } from "react";
import "./App.css";
import Navbar from "./components/navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { UserProvider } from "./services/userContext";
import { getRoutes } from "./utils/pageUtils";
import ProtectedRoute from "./components/protectedRoute";
import LoadingPage from "./components/pages/loadingPage";
import pages from "./pages";

function App() {
  const TencoBlue = "#183762"; // colors extracted from CityScale or turner-engineering.com
  const TencoOrange = "#ff8614";

  const theme = createTheme({
    palette: {
      primary: { main: TencoBlue },
      secondary: { main: TencoOrange },
    },
  });

  return (
    <>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Navbar />
            <Suspense fallback={<LoadingPage />}>
              <Routes>{getRoutes(pages, Route, ProtectedRoute)}</Routes>
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </UserProvider>
    </>
  );
}

export default App;
