export function getUserAuthorized(user, authLevel) {
  const authorized =
    authLevel === 0 ||
    (authLevel === 1 && user) ||
    (authLevel === 2 && user && user.isAdmin);
  return authorized;
}

export function pageVisibleInNavbar(page, user) {
  const { hidden, authLevel } = page;
  const userAuthorized = getUserAuthorized(user, authLevel);
  return !hidden && userAuthorized;
}

export function validatePage(page) {
  // crash everything if pages not formatted correctly. Thanks...
  const { name, path, authLevel, component } = page;
  if (name === undefined || name === "") {
    throw new Error(
      `Every page must have a name. Page: ${JSON.stringify(page)}`
    );
  }
  if (authLevel === undefined) {
    throw new Error(`authLevel undefined for page: "${name}"`);
  }
  if (path === undefined) {
    throw new Error(`path undefined for page: "${name}"`);
  }
  if (component === undefined) {
    throw new Error(`component undefined for page: "${name}"`);
  }
}

export function getRoute(page, Route, ProtectedRoute) {
  if (page.authLevel === 0) {
    return <Route path={page.path} element={page.component} key={page.path} />;
  } else if (page.authLevel === 1 || page.authLevel === 2) {
    return (
      <Route
        path={page.path}
        element={
          <ProtectedRoute
            component={page.component}
            authLevel={page.authLevel}
          />
        }
        key={page.path}
      />
    );
  } else {
    throw new Error(`Invalid authLevel: ${page.authLevel}`);
  }
}

export function getRoutes(pages, Route, ProtectedRoute) {
  return pages.map((page) => {
    if (page.subPages) {
      return getRoutes(page.subPages, Route, ProtectedRoute); // theoretically this should work for sub-sub-pages, but I haven't tested it
    } else {
      validatePage(page);
      return getRoute(page, Route, ProtectedRoute);
    }
  });
}
