import * as React from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import { useTheme } from "@emotion/react";

import NavLogo from "./navLogo";
import NavButtons from "./navButtons";
import UserAvatar from "./userAvatar";
import { useUser } from "../services/userContext";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const defaultTheme = useTheme();
  const location = useLocation();

  function getTheme(defaultTheme) {
    const isLanding = location.pathname.includes("landing");
    const TencoBlueLight = "#2c5ccc";
    const white = "#ffffff";

    const themeLight = createTheme({
      palette: {
        primary: { main: white },
        secondary: { main: TencoBlueLight },
        landingWhite: { main: white },
      },
    });
    const theme = isLanding ? themeLight : defaultTheme;
    return { theme, isLanding };
  }

  const { theme, isLanding } = getTheme(defaultTheme);

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="fixed"
        color={isLanding ? "landingWhite" : "primary"}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
          transition: "background-color 0.5s ease-out",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ minHeight: "10px" }}>
            <NavLogo />
            {isLanding ? (
              <></>
            ) : (
              <>
                <Box sx={{ flexGrow: 1 }}>
                  <NavButtons />
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                  {user ? (
                    <UserAvatar />
                  ) : (
                    <Tooltip title="Login">
                      <Button
                        onClick={() => navigate("/login")}
                        color="secondary"
                        variant="outlined"
                      >
                        Log in
                      </Button>
                    </Tooltip>
                  )}
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {/* Toolbar is a spacer to push the content below the navbar */}

      <Toolbar />
    </ThemeProvider>
  );
};

export default Navbar;
