import { lazy } from "react";
import { Navigate } from "react-router-dom";

// See: https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const HomePage = lazy(() => import("./components/pages/homePage"));
const DrawPage = lazy(() => import("./components/pages/adminPages/drawPage"));
const LandingPage = lazy(() =>
  import("./components/pages/adminPages/landingPage")
);
const GtfsExplorerPage = lazy(() =>
  import("./components/pages/adminPages/gtfsExplorerPage")
);
const TrafficSignalDemoPage = lazy(() =>
  import("./components/pages/adminPages/trafficSignalDemoPage")
);
const PlaceholderPage = lazy(() =>
  import("./components/pages/placeholderPage")
);
const LoginPage = lazy(() => import("./components/pages/loginPage"));
const NotFoundPage = lazy(() => import("./components/pages/notFoundPage"));
const LightRailPage = lazy(() =>
  import("./components/pages/adminPages/landingPages/lightRailPage")
);
const CityBusPage = lazy(() =>
  import("./components/pages/adminPages/landingPages/cityBusPage")
);
const WavePage = lazy(() =>
  import("./components/pages/adminPages/landingPages/wavePage")
);
const XAlertPage = lazy(() =>
  import("./components/pages/adminPages/landingPages/xAlertPage")
);
const ResultsPage = lazy(() =>
  import("./components/pages/adminPages/landingPages/resultsPage")
);

const statPages = [
  {
    name: "⚠️ [Intersection Speed Profiles]",
    path: "/stats/speed-profiles",
    authLevel: 1,
    component: <PlaceholderPage name="Intersection Speed Profiles" />,
  },
  {
    name: "⚠️ [Intersection Stop Percent]",
    path: "/stats/stop-percent",
    authLevel: 1,
    component: <PlaceholderPage name="Intersection Stop Percent" />,
  },
  {
    name: "⚠️ [Intersection Stop TOD]",
    path: "/stats/stop-tod",
    authLevel: 1,
    component: <PlaceholderPage name="Intersection Stop TOD" />,
  },
  {
    name: "🔒 Admin Test Page",
    path: "/stats/stats",
    authLevel: 2,
    component: <PlaceholderPage name="Admin Test Page" />,
  },
  // { name: "Intersection Stop Duration", path: "/stats/stop-duration" },
  // { name: "Station Headway", path: "/stats/statsion-headway" },
  // { name: "Live Headway", path: "/stats/live-headway" },
];

const adminPages = [
  {
    name: "🌎 GTFS Explorer",
    path: "/admin/gtfs-explorer",
    component: <GtfsExplorerPage />,
    authLevel: 2,
  },
  {
    name: "✨ Landing Page",
    path: "/admin/landing",
    component: <LandingPage />,
    authLevel: 2,
  },
  {
    name: "🚦 Traffic Signal Control Demo",
    path: "/admin/traffic-signal-demo",
    component: <TrafficSignalDemoPage />,
    authLevel: 2,
  },
  {
    name: "(✨) Speeding Light Rail in the City",
    path: "/admin/landing/light-rail",
    component: <LightRailPage />,
    authLevel: 2,
  },
  {
    name: "(✨) BRT and City Bus Improvements",
    path: "/admin/landing/city-bus",
    component: <CityBusPage />,
    authLevel: 2,
  },

  {
    name: "(✨) CityScale Wave",
    path: "/admin/landing/wave",
    component: <WavePage />,
    authLevel: 2,
  },

  {
    name: "(✨) X-Alert!",
    path: "/admin/landing/x-alert",
    component: <XAlertPage />,
    authLevel: 2,
  },
  {
    name: "(✨) Results",
    path: "/admin/landing/results",
    component: <ResultsPage />,
    authLevel: 2,
  },
  {
    name: "📌 Define Layouts",
    path: "/admin/define-layouts",
    component: <DrawPage />,
    authLevel: 2,
  },
  // { name: "CBD Status", path: "/admin/cbd-status" },
  // { name: "Traffic Signal Bands", path: "/admin/traffic-signal-bands" },
  // { name: "Anonymous Phone Data", path: "/admin/anon-phone-data" },
  // { name: "Traversal Data", path: "/admin/traversal-data" },
  // { name: "Trip Playback", path: "/admin/trip-playback" },
  // { name: "Layout Tables", path: "/admin/layouts-tables" },
  // { name: "Poly Line Check", path: "/admin/poly-line-check" },
  // {
  //   name: "GTFS Density Map Sequence",
  //   path: "/admin/gtfs-density-map-sequence",
  // },
];

const hiddenPages = [
  {
    name: "Login",
    path: "/login",
    component: <LoginPage />,
    authLevel: 0,
    hidden: true,
  },
  {
    name: "404",
    path: "*",
    component: <NotFoundPage />,
    authLevel: 0,
    hidden: true,
  },
  {
    name: "Home Redirect",
    path: "/",
    component: <Navigate to="/home" replace />,
    authLevel: 0,
    hidden: true,
  },
];

// at the moment, can't have component and subPages
const pages = [
  {
    name: "Home",
    path: "/home",
    component: <HomePage />,
    authLevel: 0,
  },
  {
    name: "Stats",
    path: "/stats",
    subPages: statPages,
    authLevel: 1,
  },
  {
    name: "Admin",
    path: "/admin",
    subPages: adminPages,
    authLevel: 2,
  },
  ...hiddenPages,
];

export default pages;
