import { useUser } from "../services/userContext";
import { Navigate } from "react-router-dom";
import { getUserAuthorized } from "../utils/pageUtils";

// Based on: https://www.robinwieruch.de/react-router-private-routes/
export default function ProtectedRoute({ component, authLevel }) {
  const { user } = useUser();
  const authorized = getUserAuthorized(user, authLevel);
  return authorized ? component : <Navigate to="/login" />;
}
