import React from "react";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

import { logout } from "../services/axios";
import { useUser } from "../services/userContext";

const settings = ["Logout"];

export default function UserAvatar() {
  const navigate = useNavigate();
  const { user, updateUser } = useUser();

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickSetting = (setting) => {
    if (setting === "Logout") {
      logout(updateUser); // TODO: this is not neat. Want to call logout with no arguments.
      navigate("/login");
    }
  };
  return (
    <>
      {user && user.isAdmin && (
        <Tooltip
          title="Administators have elevated permissions"
          placement="bottom"
          enterDelay={500}
        >
          <Chip
            label="Admin Account"
            size="small"
            color="info"
            variant="filled"
          />
        </Tooltip>
      )}
      <Link
        component="button"
        color="inherit"
        variant="body2"
        onClick={handleOpenUserMenu}
        sx={{ p: 1.5 }}
      >
        {user && user.username}
      </Link>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem
            key={setting}
            onClick={() => {
              handleCloseUserMenu();
              handleClickSetting(setting);
            }}
          >
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
