import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import tencoLogo from "../images/tenco-logo-black.png";

import { useLocation } from "react-router-dom";
import CloudIcon from "@mui/icons-material/Cloud";

export default function NavLogo() {
  const navigate = useNavigate();
  const location = useLocation();

  // if landing in location, don't show logo
  if (location.pathname.includes("landing")) {
    return (
      <>
        {/* <CloudIcon sx={{ mr: 2 }} color={"inherit"} /> */}

        <Typography
          component="a"
          onClick={(e) => {
            e.preventDefault();
            navigate("/admin/landing");
          }}
          variant="h1"
          noWrap={true}
          href=""
          sx={{
            mr: 2,
            fontFamily: "Chakra Petch",
            fontWeight: 700,
            fontSize: "2.5rem",
            // color: "#3268e4",
            color: "#000",
            textDecoration: "none",
            // dropshadow please
            textShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          CityScale
        </Typography>
        <Typography
          variant="h6"
          noWrap={true}
          href=""
          sx={{
            mr: 2,
            fontSize: 18,
            fontFamily: "monospace",
            fontWeight: 700,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          by
        </Typography>
        <img
          src={tencoLogo}
          alt="Tenco Logo"
          style={{
            height: "2rem",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
          }}
        />
      </>
    );
  } else {
    return (
      <>
        <CloudIcon sx={{ mr: 2 }} color={"inherit"} />
        <Typography
          component="a"
          onClick={(e) => {
            e.preventDefault();
            navigate("/home");
          }}
          variant="h6"
          noWrap={true}
          href=""
          sx={{
            mr: 2,
            fontFamily: "monospace",
            fontWeight: 700,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Cloud TSP
        </Typography>
      </>
    );
  }
}
